import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import logo from "./extrinsic_logo_paths.svg";
import image_3d_extrinsic from "./assets/images/3d_extrinsic.jpg";
import image_3d_media from "./assets/images/3d_media.jpg";
import image_wedding_livestream from "./assets/images/wedding_livestream.jpg";
import stand_1 from "./assets/images/stand_1.jpg";
import stand_2 from "./assets/images/stand_2.jpg";
import stand_3 from "./assets/images/stand_3.jpg";
import stand_4 from "./assets/images/stand_4.jpg";
import stand_5 from "./assets/images/stand_5.jpg";
import stand_6 from "./assets/images/stand_6.jpg";
import stand_7 from "./assets/images/stand_7.jpg";
import "./App.css";
import ContactForm from "./ContactForm";
// import TopBar from "./TopBar";
import FooterContent from "./FooterContent";
import { Tabs, Tab } from "react-bootstrap";

class App extends React.Component {
	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll = (event) => {
		if (
			document.body.scrollTop > window.innerHeight * 0.25 ||
			document.documentElement.scrollTop > window.innerHeight * 0.25
		) {
			// document.getElementById("topbar").style.top = "0";
		} else {
			// document.getElementById("topbar").style.top = "-100%";
		}
	};

	render() {
		return (
			<div className="App">
				{/* <TopBar /> */}
				<header className="App-header">
					<img src={logo} className="App-logo noselect fadeIn" alt="logo" />
					<p className="noselect fadeIn">Multimedia and Technical Solutions</p>
				</header>
				<Tabs fill defaultActiveKey="" className="justify-content-center">
					<Tab eventKey="video" title="Videography">
						We offer a wide range of videography services. We have a large
						selection of filmmaking equipment including lighting, grip and our
						Blackmagic Pocket Cinema 4K Camera, with a vast bank of experienced
						crew for any roles you require for your next shoot. Extrinsic also
						offers production management services, having co-produced a number
						of films with key partners including the pre-production and
						management required for Covid-19 secure sets.
					</Tab>
					<Tab eventKey="photo" title="Photography">
						We have a wide bank of experienced photographers nationally, all
						sporting premium equipment. This means that we can cover any event,
						anywhere in the UK. Whether you are looking for a photographer for
						your corporate event, Gala awards dinner or wedding we have the crew
						for you. Get in touch now, so that we can discuss your event needs.
					</Tab>
					<Tab eventKey="rapid" title="Rapid Prototyping">
						We offer CAD 3D modelling and fabrication services using various
						techniques and materials. <br />
						This includes high-precision resin printing and more robust PLA or
						PETG FDM parts.
						<br />
						<div className="imageRow">
							<img alt="3D print" src={image_3d_extrinsic} />
							<img alt="3D print" src={image_3d_media} />
						</div>
					</Tab>
					<Tab eventKey="live" title="Livestreaming">
						These past few months we have been trialing a new service, that we are now
						at the point we are pleased to announce...
						<br />
						With the current pandemic, event cancellations have been an inevitable,
						but it doesn't have to be! We can live stream your event whether its a conferences,
						weddings or anything else you desire, we have the hardware, crew and know how to
						leave a positive impact on your virtual attendees.
						<br />
						Don't let attendee limits ruin your big day, with our professional services matched
						to your needs your guests can join you in your celebrations from their own home,
						and even interact! We can stream to most platforms including; facebook, youtube, zoom and more.
						<br />
						Extrinsic has a range of packages to match your needs and budget, from a singular static camera
						to multiple operator controlled cameras so none of the action is missed.
						<br />
						Get in contact now to discuss your requirements.

						<img alt="3D print" src={image_wedding_livestream} />
					</Tab>
					<Tab eventKey="rent" title="Rentals">
						Due to our broad selection of equipment, some of it is often not in
						use. If you're interested in renting some equipment for your own
						use, please check out our rental shop on Fat Llama <a href="https://fatllama.com/profile/3167131108">here</a>.
						<br />
						You can also use <a href="https://fatllama.com/r/extr-5379b">this</a> link to get £20 off your first Fat Llama rental.
					</Tab>
					<Tab eventKey="atem" title="PK1 Pro for Atem">
						Extrinsic Media is the official UK retailer for the Aaron Parecki PK1 Pro ATEM Mini/Pro/ISO stand.
						<br />
						Shipping now! for only <b>£29.99</b> including free domestic UK Shipping.
						<br />
						This stand is a perfect companion to your ATEM Mini, Mini Pro or Mini Pro ISO switcher, raising it off the desk to aid with cooling and ease of use.
						<br />
						<img alt="3D print" src={stand_6} />
						Your perfect rig! With mounting points along the sides, back and top, you can attach a wide range of accessories to your stand.
						<br />
						<img alt="3D print" src={stand_1} />
						Built-in cold shoes! the top cross bar has a built in cold shoe mount so you can attach wireless mic receivers and other accessories directly to the stand.
						<br />
						<img alt="3D print" src={stand_5} />
						Various mounting options! As well as the 4 cold shoes, there are multiple captive nuts, allowing you to attach various accessories or adaptor plates.
						<br />
						<img alt="3D print" src={stand_7} />
						Attach a cheese plate to the side, and you can mount an SSD for safe use with your ATEM.
						<br />
						<img alt="3D print" src={stand_2} />
						Space for another ATEM with clean cable management. The stand is designed to raise the ATEM mini up just enough to place another ATEM mini below it.
						<br />
						<img alt="3D print" src={stand_4} />
						The cables from the lower ATEM mini will run underneath the rig to keep your desktop clean. Use short HDMI cables and a flexible USB-C cable to keep the cables in the rear clean.
						<br />
						<img alt="3D print" src={stand_3} />
						Should you not need a second ATEM Mini, there is even enough space for a Stream Deck XL below the ATEM if you're using it with Companion.
						<br />
						Please see Aaron Parecki's site <a href="https://aaronpk.tv/pk1-pro-atem-mini-stand/">here</a>. for an assembly guide video.
						<br />
						This item is sold flat packed, so will need some minor assembly before use.
						<br />
						This item is made to order. We are currently experiencing a high number of orders so lead times may be longer than usual.
						<br />
						Colours and materials are subject to change. Colours shown are for illustration purposes only.
						<br />
						Please note, that due to the method of production some parts may have slight blemishes or visible construction marks.
					</Tab>
				</Tabs>
				<a class="big-link" href="https://www.ebay.co.uk/itm/274709898722">Buy your PK1 Pro ATEM Mini Stand here</a>
				<div style={{ height: "50px" }} />
				<ContactForm />
				<footer>
					<FooterContent />
				</footer>
			</div>
		);
	}
}

export default App;
