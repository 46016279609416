import React, { Component } from "react";

export default class ContactForm extends Component {
	render() {
		return (
			<section id="contact">
				<div className="container contactSection">
					<div className="row">
						<div className="col-lg-12 text-center">
							<h2>Contact</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-8 col-lg-offset-2">
							<form
								action="//formspree.io/contact@extrinsic.co.uk"
								method="POST"
								name="sentMessage"
								id="contactForm"
								noValidate
							>
								<div className="row control-group">
									<div className="form-group col-xs-12 floating-label-form-group controls">
										<label htmlFor="name">Name</label>
										<input
											type="text"
											name="name"
											className="form-control"
											placeholder="Name"
											id="name"
											required
											data-validation-required-message="Please enter your name."
										/>
										<p className="help-block text-danger"></p>
									</div>
								</div>
								<div className="row control-group">
									<div className="form-group col-xs-12 floating-label-form-group controls">
										<label htmlFor="email">Email Address</label>
										<input
											type="email"
											name="_replyto"
											className="form-control"
											placeholder="Email Address"
											id="email"
											required
											data-validation-required-message="Please enter your email address."
										/>
										<p className="help-block text-danger"></p>
									</div>
								</div>
								<div>
									<input
										type="hidden"
										name="_subject"
										value="New submission!"
									/>
									<input
										type="text"
										name="_gotcha"
										style={{ display: "none" }}
									/>
								</div>
								<div className="row control-group">
									<div className="form-group col-xs-12 floating-label-form-group controls">
										<label htmlFor="message">Message</label>
										<textarea
											rows="5"
											name="message"
											className="form-control"
											placeholder="Message"
											id="message"
											required
											data-validation-required-message="Please enter a message."
										></textarea>
										<p className="help-block text-danger"></p>
									</div>
								</div>
								<br />
								<div id="success"></div>
								<div className="row">
									<div className="form-group col-xs-12">
										<button type="submit" className="btn btn-success btn-lg">
											Send
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
