import React, { Component } from "react";

export default class FooterContent extends Component {
	render() {
		return (
			<div id="footer" className="noselect">
				<span>© Extrinsic Ltd. {new Date().getFullYear()}</span>
				<span>Company No. 12081627</span>
			</div>
		);
	}
}
